/* Instructions.css */
.instructions-container {
  max-width: 360px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center; /* Add this for overall center alignment */
  font-family: frontfont, arial;
}

.modes-section {
  margin: 2rem 0;
  padding: 1rem;
  background: darkslategray;
  border-radius: 8px;
}


.mode-card {
  padding: 1rem;
  background: darkblue;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.mode-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}


.navigation-sections {
  display: grid;
  gap: 1.5rem;
  margin: 2rem 0;
  justify-items: center; /* Center grid items horizontally */
}

.instruction-card {
  margin: 1rem 0;
  padding: 1rem;
  background: darkslategray;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  width: 90%; /* Control card width */
  max-width: 324px; /* Maximum card width */
  text-align: left; /* Keep content left-aligned */
}

.step-number {
  position: absolute;
  top: -10px;
  left: -10px;
  background: purple;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-diagram {
  margin: 2rem auto; /* Center the diagram box */
  max-width: 360px;
  padding: 2rem;
  background: steelblue;
  border-radius: 8px;
  text-align: center;
}

.flow {
  font-size: 1.2rem;
  color: #666;
  margin: 1rem 0;
}

.mode-indicator {
  margin-top: 1rem;
  padding: 1rem;
  background: purple;
  border-radius: 4px;
  font-weight: bold;
}

.footer-note {
  text-align: center;
  margin-top: 2rem;
}


.back-to-app-button {
  margin: 10px auto;
  height: 60px;
  width: 350px;
  display: flex;
  font-family: frontfont;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition: 250ms;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  background: #00356e;
  border: none;  
}
