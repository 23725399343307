/* Author: 劉喆 John Liu
   License: GNU General Public License Version 3 (GPLv3) */

.mainmenu {
}

.menu {
  margin: 10px auto;
  font-family: frontfont;
  height: 60px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  border-radius: var(--border-radius);
  position: relative;
  cursor: pointer;
  transition: 250ms;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

* {
      touch-action: manipulation;
  }

.disable-doubletap-to-zoom {
    touch-action: none;
}



.line-above-buttons {
  width: 100%;
  height: 1px;
  background-color: #000; /* Adjust the color as needed */
  margin-bottom: 10px; /* Adjust the spacing between the line and buttons */
}

.footer {
  text-align: center;
  color: #eeeeee;
  width: 350px; /* Match menu width */
  margin: 1em auto 0; /* Center container */
  position: relative; /* For absolute positioning */
}

.topbodyb {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  touch-action: manipulation;
}

.topbody {
  width: var(--layout-width);
  margin: auto;
  display: flex;
  justify-content: space-around;
}

.topbody button {
  border: none;
  background: #111122;
  color: white;
  padding: 15px 20px;
  border-radius: 15px;
  margin: 0.6em;
  margin-top: 10px;
  min-width: 70px;
  background-color: black;
  opacity: 0.85;
  transition: all 0.25s;
  cursor: pointer;
  line-height: 48px;
  display: inline-flex;
  touch-action: manipulation;
  transition: background-color 0.3s ease;
  position: relative; 
  justify-content: center;
  align-items: center;
}

.topbody button:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.topbody button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  transform: translateY(0px);
}

.topbody .toggle-button{
  padding: 15px 14px;
}

.topbody .write-toggle-button{
  padding: 15px 14px;
}

.topbody .write-toggle-button.nowrite{
  background-color: black;
  opacity: 30%;
}

.topbody .write-toggle-button.write{
  background-color: #0352fc;
}


.topbody .toggle-button.隨機順序 {
  background-color: purple;
}

.topbody .toggle-button.正常秩序 {
  background-color: black;
  opacity: 30%;
}

.topbody .speakerButton {}


.topbody .userListButton {}


.topbody .homeButton {}


.overlay-container {
  position: relative;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #fff; /* #87898c Adjust the color as needed */
  border: none;
  margin: 0; /* Remove default margin to avoid unexpected spacing */
}

.overlay-text {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
  background-color: #242934; /* Adjust the background color as needed */
  padding: 0 0px; /* Adjust the padding as needed */
  white-space: nowrap; /* Prevent text from wrapping */
}

.appNameButton {
  margin: 10px auto;
  font-family: frontfont;
  height: 60px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition: 250ms;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  background: #242934;
  border: none;
}

.usage-button {
  position: absolute;
  height: 40px;
  width: 80px;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: frontfont;
  background: #00356e;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px; /* Adjust font size if needed */
}

.usage-button:hover {
  background: #014894;
}


.publisher-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.publisher-dropdown-button {
  /* Keep all existing button styles */
  font-family: frontfont;
  height: 40px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 250ms;
  background: #212196;
  border: none;
}

.publisher-dropdown-button:hover {
  background: #2828b5;
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background: blue;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.menu-item {
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-item:hover {
  background-color: #343f4f;
}

.homebutton {
  border: none;
  border-radius: 15px;
  font-size: 16px;
  position: relative; 
  background: #111122;
  margin: 10px;
  display: inline-flex;
  padding: 8px 15px;
  justify-content: center;
}


.basemenu {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  touch-action: manipulation;
}

.basemenu button {
  border: none;
  background: #111122;
  color: white;
  border-radius: var(--border-radius);
  margin: 0.4em;
  opacity: 0.9;
  transition: all 0.25s;
  cursor: pointer;
  font-size: 30px;
  font-family: 'frontfont';
  justify-content: center;
  line-height: 36px;
  height: 148px;
  display:flex;
  align-items: center;
  touch-action: manipulation;
  padding: 20px;
}

.basemenu button:hover {
  opacity: 1;
  transform: translateY(-2px);
}


/*  */

.mode-toggle-button {
  font-family: frontfont;
  font-size: 16px;  /* Adjust to match the size of other buttons */
  padding: 15px 20px; /* Match the padding of other buttons */
  border-radius: 15px;
  background-color: black;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-flex; /* Same as the other buttons */
  justify-content: center;
  align-items: center;
  line-height: 48px; /* To match the height of other buttons */
  min-width: 70px;  /* Same minimum width as other buttons */
}

.mode-toggle-button .text {
  transform: scale(1.6); /* Increase text size without affecting button size */
  transform-origin: center;
  display: inline-block;
  font-size: 16px; /* Set initial font size */
  line-height: 1.2;
}

.mode-toggle-button.學 {
  background-color: var(--color-學); /* Mode A color */
}

.mode-toggle-button.練 {
  background-color: var(--color-練); /* Mode B color */
}

.mode-toggle-button.寫 {
  background-color: var(--color-寫); /* Mode C color */
}


/* Add additional button styles for hover/focus if needed */
.mode-toggle-button:hover {
  opacity: 0.8;
}

.mode-toggle-button:active {
  opacity: 1;
}

