/* Author: 劉喆 John Liu
   License: GNU General Public License Version 3 (GPLv3) */

.card {
  margin: 10px auto;
  background: var(--grad-one);
  height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  color: #ffffff;
  font-size: 90px;
  font-weight: 400;
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  /* animated interactivity */
  transition: transform 0.5s ease, opacity 0.5s ease;
/*  transition: 250ms;*/
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

* {
      touch-action: manipulation;
  }

.disable-doubletap-to-zoom {
    touch-action: none;
}

/* when card is clicked adding .side class */
.side {
  background: var(--grad-two);
  transform: rotateY(-180deg);
}

/* card content */
.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
  text-align: center;
}

.card .front {
/*  font-family: '王漢宗中明體繁';*/
  font-family: 'frontfont';
  transform: rotateY(0deg);
  animation: bounce 0.6s ease forwards; /* Apply the bounce animation */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

}

.card.side .front {
  opacity: 0;
  display: none;
}

.card .back {
/*  font-family: '王漢宗中明體注音';*/
/*  font-family: 'backfont';*/
  transform: rotateY(180deg);
/*  padding-left:60px;*/
  padding-bottom:48px;
  z-index:2;
}

.card .back .doublefont {
  position: absolute;
  font-family: radicals;
  color: yellow;
  top: +16px;
  z-index:3;
}

.frontfont {
  font-family: 'frontfont', serif; /* Replace with the actual font name */
}

.backfont {
  font-family: 'backfont', serif; /* Replace with the actual font name */
}


.bottomalign {
  position: absolute;
  top: 80%;
  left: 50%;
  padding-left:0px;
/*  padding-bottom:0px;*/
  transform: translateX(-50%);
/*  transform: translateY(-50px);*/
  text-align: center;
  font-size: 40px;
  white-space: nowrap;
}

.bottomalign hr {
  margin-top:15px;
  margin-bottom:5px;  
}

.card small {
  opacity: 0.7;
  font-size: 0.7em;
  position: absolute;
  top: 3rem;
}

.card.side small {
  transform: rotateY(180deg);
}

.card small span {
  display: block;
  text-align: center;
  font-size: small;
}

.loading {
  color: white;
  width: 350px;
  text-align: center;
  margin: 20px auto;
}

.large-font {
  font-size: 90px; /* Adjust the size as needed */
}

.medium-font {
  font-size: 70px; /* Adjust the size as needed */
}

.small-font {
  font-size: 40px; /* Adjust the size as needed */
}

.card-top {
  position: absolute;
  top: 5px;
  right: 10px;
  left: 10px; /* Adjusted to ensure add-remove-button stays on the upper left */
  display: flex;
  justify-content: space-between;
  transform-style: preserve-3d; /* Ensure 3D transformations are preserved */
  transition: transform 250ms; /* Add a transition for a smoother effect */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.card-bottom {
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px; /* Adjusted to ensure add-remove-button stays on the upper left */
  display: flex;
  justify-content: space-between;
  transform-style: preserve-3d; /* Ensure 3D transformations are preserved */
  transition: transform 250ms; /* Add a transition for a smoother effect */
}

.card-bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px; /* Position the button to the bottom-right */
}

.toggle-visibility-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}


.canvas-button,
.pronounce-button,
.undo-button,
.add-remove-button,
.toggleside1-button {
  padding: 10px 20px;
  color: #fff;
  border: none;
  cursor: pointer;
  color: #0702a8;
  background-color: transparent;
  border-radius: 10px;
}

@keyframes bounce {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  20% {
    transform: translateY(-10px) scale(1.1);
    opacity: 0.2;
  }
  40% {
    transform: translateY(0) scale(1);
    opacity: 0.4;
  }
  60% {
    transform: translateY(-5px) scale(1.05);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.replay-button {
  border: none;
  color: white;
  background-color: transparent;
  font-size: 36px;
  font-weight: 400;
}

.replay-button .eye-icon-transition {
  animation: bounce 0.6s ease forwards; /* Apply the bounce animation */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}


/* Adjusted styles for the flipped state */
.side .card-top,
.side .card-bottom {
  transform: rotateY(180deg);
}

.side .pronounce-button {
  right: 10px;
  left: auto;
}

.side .add-remove-button {
  right: auto;
  left: 10px;
}

.side .add-remove-button {
  right: auto;
  left: 10px;
  bottom: 10px;
}

.nav2fr .play {
  background-color: black;
  height: ;
}

.nav2fr .stop {
  background-color: darkblue;
}

.progress-bar-container {
  width: var(--layout-width);
  display: flex;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  margin: 10px auto;
  align-items: center;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
}

.progress-number {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 12px; /* Adjust the font size as needed */
  color: black;
}

.speed-button-container {
  width: var(--layout-width);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  touch-action: manipulation;
}


.speed-button-container button {
  border: none;
  background: #111122;
  color: white;
  font-size: 26px;
  padding: 15px 20px;
  border-radius: var(--border-radius);
  margin: 15px;
  opacity: 0.85;
  transition: all 0.25s;
  cursor: pointer;
  display:flex;
  justify-content: center;
  touch-action: manipulation;
  color: white; /* Set the text color */
  font-family: frontfont;
}

.speed-button-container button:hover {
  opacity: 1;
  transform: translateY(-2px);
}


.speed-button-container button.speedup {
  background-color: #C32148; /* Set the background color */
}

.speed-button-container button.speeddown {
  background-color: #277155; /* Set the background color */
}


.cardNumber {
  font-size: 16pt;
  font-family: frontfont;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}


.canvas-container {
  position: absolute;
  bottom: 0; /* Align to the bottom of the card */
  left: 0;
  width: 100%;
  height: 80%; /* Take up the bottom 3/4 of the card */
  padding: 0px;
  background-color: white; /* Optional to make sure background matches */
  border-bottom-left-radius: 15px; /* Rounded bottom-left corner */
  border-bottom-right-radius: 15px; /* Rounded bottom-right corner */
  overflow: hidden; /* Prevents overflow in case the canvas grows */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.canvas-control-button {
  background: none;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.canvas-control-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.stroke-counter {
  font-size: 36px;
  font-family: 'math';
  color: white;
  padding: 10px;
  border: none;
  background: none;
  width: 50px;
  padding-right: 10px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
